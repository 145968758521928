import axios from "axios";
// import {showToast} from "@/utils/functions";
import store from "@/store";
import VueCookies from "vue-cookies";
import refreshTokeninterceptor from "./utils.js";
import Vue from "vue";
import {EventBus} from "@/main.js";

// export default
const osintDataUrl = axios.create({
    baseURL: `${process.env.VUE_APP_OSINT_ENGINE_URL}/api/v1`,
    // baseURL: process.env.NODE_ENV === "production" ? `/osint-api/api/v1` : `${process.env.VUE_APP_OSINT_ENGINE_URL}/api/v1`,
});
osintDataUrl.interceptors.request.use(
    (config) => {
        let token = VueCookies.get("token");
        if (VueCookies.get("wlp_token") !== null) token = VueCookies.get("wlp_token");
        else token = VueCookies.get("token");
        if (token) {
            config.headers["Authorization"] = `Bearer ${token}`;
        }
        if (VueCookies.get("wlp_backend_url") !== null) {
            config.headers["x-wlp-reference"] = VueCookies.get("wlp_backend_url");
        }
        config.headers["Accept"] = "application/json";
        // config.headers["Authorization"] = "Basic bmVvdXNlcjpub3RhZGVtb0AxMjM=";
        config.headers["x-status"] = "RUNNING";
        config.headers["x-case-id"] = store.getters.getCaseId == null ? "" : store.getters.getCaseId;
        config.headers["x-user-id"] = store.getters.getUserAuthDetails.userId;
        if (store.getters?.getCasePersonalData?.data) config.headers["x-case-product"] = store.getters?.getCasePersonalData?.data["Due Diligence Level"]["value"] == null ? " " : store.getters?.getCasePersonalData?.data["Due Diligence Level"]["value"];
        else config.headers["x-case-product"] = "";

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

osintDataUrl?.interceptors?.response.use(
    function(response) {
        if (response.status === 200) EventBus.$emit("refreshCreditPacks");
        return response;
    },
    function(error) {
        if (error.response && error.response.status === 406 && error.response) Vue.$toast.error(error.response.data.detail);
        if (error.response && error.response.status === 403 && error.response && error.response.data.detail === "Provided delvium token is not valid.") {
            VueCookies.remove("token");
            window.open(VueCookies.get("wlp_frontend_url"), "_self");
        }
    }
);
osintDataUrl.interceptors.response.use(undefined, refreshTokeninterceptor(osintDataUrl));
export default osintDataUrl;
